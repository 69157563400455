import { Component, Inject } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { feedAnimations } from "app/animations";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
	selector: "formaciones-dialog",
	templateUrl: "formaciones-dialog.component.html",
	styleUrls: ["formaciones-dialog.component.scss"],
	animations: [feedAnimations],
})
export class FormacionesDialog {
	selectedCurso: any;
	formControl = new UntypedFormControl();
	cursos: any;
	filteredCursos: Observable<any[]>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public evaluationSvc: EvaluationService,
		private snackBar: MatSnackBar,
	) {
		this.evaluationSvc.getFormaciones(data.puesto).subscribe((data) => {
			this.cursos = data;
			this.initCursos();
		});
	}

	initCursos() {
		this.filteredCursos = this.formControl.valueChanges.pipe(
			startWith(""),
			map((value) => this._filter(value)),
		);
	}

	onSelectCurso() {
		this.selectedCurso = this.cursos.find((curso) => curso.name === this.formControl.value);
	}

	clear() {
		this.selectedCurso = null;
		this.formControl.reset();
		this.initCursos();
	}

	confirm() {
		this.evaluationSvc.sendFormacion(this.data.personID, this.selectedCurso).subscribe((data) => {
			if (data) {
				this.snackBar.open("Se ha enviado un email al colaboradorr recomendando la formación.", "X", {
					duration: 3000,
					verticalPosition: "top",
				});
				this.close();
			}
		});
	}

	close() {
		this.data.close();
	}

	private _filter(value: string): string[] {
		if (value) {
			const filterValue = value.toLowerCase();
			return this.cursos.filter((option) => option.name.toLowerCase().includes(filterValue));
		} else {
			return this.cursos;
		}
	}
}
