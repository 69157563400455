import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";

@Component({
	selector: "item-stars",
	templateUrl: "./item-stars.component.html",
	styleUrls: ["./item-stars.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemStarsComponent implements OnInit {
	@Input() stars: Array<String>;
	@Input() view: string;
	@Input() editable: boolean;

	@Output() onStarsChange = new EventEmitter();

	// currentStars: Array<String>;

	constructor(public _changer: ChangeDetectorRef) {
		this.stars = ["-", "-", "-", "-", "-"];
		this.editable = false;
	}

	ngOnInit(): void {
		this.editable = this.view === "me" && this.editable ? true : false;
		// this.currentStars = this.stars;
	}

	setStar(id) {
		const currentStars = this.printStars(id);
		this.onStarsChange.emit(currentStars);
	}

	printStars(index) {
		let _array = new Array(5);
		for (let i = 0; i < 5; i++) {
			if (i < index + 1) {
				_array[i] = "X";
			} else {
				_array[i] = "-";
			}
		}
		return _array;
	}

	// Rellena las estrellas sobre las que pasa el ratón
	enterMouse(evt) {
		const _stars = evt.target.parentElement.children;
		const _index = evt.target.id;
		for (let star of _stars) {
			if (star.id <= _index) {
				star.children[0].style.color = "rgb(243, 156, 18)";
			} else {
				star.children[0].style.color = "darkgray";
			}
		}
	}

	// Al salir el ratón, rellenar las estrellas seleccionadas
	leaveMouse(evt) {
		const _stars = evt.target.parentElement.children;
		for (let i = 0; i < 5; i++) {
			if (this.stars[i] == "X") {
				_stars[i].children[0].style.color = "rgb(243, 156, 18)";
			} else {
				_stars[i].children[0].style.color = "darkgray";
			}
		}
	}
}
