import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTooltipModule } from "@angular/material/tooltip";

import { TranslateModule } from "@ngx-translate/core";

import { FuseSharedModule } from "@fuse/shared.module";
import { SendEvaluationComponent } from "./send-evaluation-dialog/send-evaluation-dialog.component";
import { VueltaDFormComponent } from "./vuelta-d-form/vuelta-d-form.component";
import { VueltaDComponent } from "./vuelta-d.component";

import { MatMenuModule } from "@angular/material/menu";
import { SharedComponentsModule } from "app/components/shared-components.module";
import { QuillModule } from "ngx-quill";
import { FilesComponent } from "./files-component/files.component";
import { FormacionesDialog } from "./formaciones-dialog/formaciones-dialog.component";
import { ItemSelectComponent } from "./item-select/item-select.component";
import { ItemStarsComponent } from "./item-stars/item-stars.component";
import { JobSectionComponent } from "./job-section/job-section.component";

const routes = [
	{
		path: "vuelta-d",
		component: VueltaDComponent,
	},
	{
		path: "vuelta-d/form",
		component: VueltaDFormComponent,
	},
	{
		path: "vuelta-d/job-section",
		component: JobSectionComponent,
	},
];

@NgModule({
	declarations: [
		VueltaDComponent,
		VueltaDFormComponent,
		JobSectionComponent,
		ItemStarsComponent,
		SendEvaluationComponent,
		FilesComponent,
		FormacionesDialog,
		ItemSelectComponent
	],
	imports: [
		RouterModule.forChild(routes),

		FuseSharedModule,
		TranslateModule,
		SharedComponentsModule,

		MatFormFieldModule,
		MatTooltipModule,
		MatProgressBarModule,
		MatStepperModule,
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatSelectModule,
		MatMenuModule,
		MatAutocompleteModule,

		QuillModule.forRoot({
			modules: {
				syntax: false,
				toolbar: {
					container: [
						["bold", "italic", "underline", "strike"],
						[{ list: "ordered" }, { list: "bullet" }],
						[{ size: ["small", false, "large", "huge"] }],
						[{ header: [1, 2, 3, 4, 5, 6, false] }],
						["link", "image"],
					],
				},
			},
		}),
	],
})
export class VueltaDModule {}
