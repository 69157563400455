<ng-container *ngIf="!editable && result" >
	<mat-form-field dense *ngIf="selectOptions.length" appearance="outline">
		<mat-select class="form-control disabled" [(value)]="result" disabled>
			<mat-option *ngFor="let option of selectOptions" [value]="option.value">{{ option.name }}</mat-option>
			<mat-option *ngIf="element.noApplyOption" value="NA">No aplica</mat-option>
		</mat-select>
	</mat-form-field>
</ng-container>

<!-- EditMode -->
<ng-container *ngIf="editable">
	<mat-form-field dense *ngIf="selectOptions.length" appearance="outline">
		<mat-select class="form-control" #filterSelect [(value)]="result" (selectionChange)="setStar()">
			<mat-option *ngFor="let option of selectOptions" [value]="option.value">{{ option.name }}</mat-option>
			<mat-option *ngIf="element.noApplyOption" value="NA">No aplica</mat-option>
		</mat-select>
	</mat-form-field>
</ng-container>