<!-- Editable -->
<div id="item-stars" *ngIf="editable">
	<div
		[ngClass]="{ me: view === 'me', pointer: editable === true }"
		[id]="i"
		*ngFor="let star of stars; let i = index"
		(click)="setStar(i)"
		(mouseenter)="enterMouse($event)"
		(mouseleave)="leaveMouse($event)"
	>
		<mat-icon class="golden" *ngIf="star == 'X'">star</mat-icon>
		<mat-icon class="gray" *ngIf="star == '-'">star</mat-icon>
	</div>
</div>

<!-- Static -->
<div id="item-stars" *ngIf="!editable">
	<div [ngClass]="{ me: view === 'me', pointer: editable === true }" [id]="i" *ngFor="let star of stars; let i = index">
		<mat-icon class="golden" *ngIf="star == 'X'">star</mat-icon>
		<mat-icon class="gray" *ngIf="star == '-'">star</mat-icon>
	</div>
</div>
