export class LocalStorage {
	public static save(key: string, obj: any): any {
		const objString = JSON.stringify(obj);
		return localStorage.setItem(key, objString);
	}

	public static get(key: string): any {
		return JSON.parse(localStorage.getItem(key));
	}

	public static remove(key: string): void {
		localStorage.removeItem(key);
	}
}
