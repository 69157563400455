<div id="vuelta-d" class="page-layout simple fullwidth p-24">
	<div @onRenderUp fxLayout="column">
		<div fxLayout="column" fxLayoutAlign="none" @onRenderUp>
			<div fxLayout="row" fxLayoutAlign="start center" class="header">
				<!--
				<img class="png-icon" src="assets/images/logos/vuelta-d.svg" />
				-->
				<p>{{ evaluationPeriod.name }}</p>
			</div>

			<div fxLayout="row">
				<!--div *ngIf="evaluation && !evaluation.formId" class="fuse-card card-border-top card-vuelta">Tu perfil no está configurado</div-->
				<div *ngIf="evaluation && evaluation.formId" class="fuse-card card-border-top card-vuelta">
					<div fxLayout="row">
						<div fxLayout="column" fxLayoutAlign="center center" class="user-container">
							<div class="person-avatar" fxLayout="row" fxLayoutAlign="center flex-start">
								<img class="img-circle" [src]="getImagePerson(user && user.username)"
									[alt]="user && user.username" />
							</div>
							<h5 class="text-center">{{ user && user.displayName }}</h5>
							<h5 class="text-center">{{ user && user.puestos.length && user.puestos[0].puesto }}</h5>
						</div>

						<div fxLayout="column" class="data-container">
							<div class="m-4" fxLayout="row" fxLayoutAlign="flex-start center"
								*ngIf="(tandemId != projectId)">
								<span class="mr-8">Mi valoración:</span>
								<!-- <item-stars [stars]="getStarsIndex(evaluation.avgEvaluations)"></item-stars> -->
								<span *ngFor="let star of getStarsIndex(evaluation.avgEvaluations)">
									<mat-icon class="golden" *ngIf="star == 'X'">star</mat-icon>
									<mat-icon class="gray" *ngIf="star == '-'">star</mat-icon>
								</span>
							</div>
							<div class="m-4" fxLayout="row" fxLayoutAlign="flex-start center">
								<span *ngIf="(tandemId != projectId)" class="mr-8">Nº de vueltas:</span>
								<span *ngIf="(tandemId == projectId)" class="mr-8">Registros enviados este año:</span>
								<span [ngClass]="
										evaluation && evaluation.iterationNumber == 0 ? 'red' : evaluation && evaluation.iterationNumber >= 4 ? 'green' : 'orange'
									" class="vueltas">{{ evaluation && evaluation.iterationNumber }}</span>
							</div>
							<div class="m-4" fxLayout="row" fxLayoutAlign="flex-start center">
								<span class="mr-8">Últ. registro:</span>
								<span>{{ evaluation && formatDate(evaluation.updateDate) }}</span>
							</div>
							<div *ngIf="evaluation && evaluation.iterationNumber == 0 && canDo" class="m-4"
								fxLayout="row" fxLayoutAlign="flex-start center">
								<button mat-raised-button class="btn-feedbalia btn-vuelta-d"
									(click)="loadAutoEvaluation(evaluation, true)">Nuevo Registro Diario</button>
							</div>
							<div *ngIf="evaluation && evaluation.iterationNumber > 0 && evaluation.evaluationStatus == 'DONE'"
								class="m-4" fxLayout="row" fxLayoutAlign="flex-start center">
								<button mat-raised-button class="btn-feedbalia btn-vuelta-d"
									(click)="loadAutoEvaluation(evaluation, false)">Ver Último Registro</button>
							</div>
							<div *ngIf="evaluation && evaluation.iterationNumber > 0 && evaluation.evaluationStatus == 'DONE'"
								class="m-4" fxLayout="row" fxLayoutAlign="flex-start center">
								<button mat-raised-button class="btn-feedbalia btn-vuelta-d"
									(click)="loadAutoEvaluation(evaluation, true)" *ngIf="canDo">
									Nuevo Registro Diario
								</button>
								<button mat-raised-button class="btn-feedbalia disabled-button" *ngIf="!canDo" disabled>
									Ya has envidado tu registro hoy
								</button>
							</div>
							<a *ngIf="(tandemId != projectId)" [href]="getCalendarLink(null)" target="_blank"
								class="m-4 clickable" fxLayout="row" fxLayoutAlign="flex-start center">
								<mat-icon class="icon-calendar">event</mat-icon>
								<h6 class="m-0 pl-4 font-size-14">Agendar reunión</h6>
							</a>
							<mat-select
								*ngIf="allUserEvaluations && allUserEvaluations.length && (tandemId != projectId)"
								class="select-vueltas" panelClass="select-panel" placeholder="Histórico">
								<ng-container *ngIf="(tandemId != projectId)">
									<mat-option *ngFor="let evaluation of allUserEvaluations"
										(click)="loadEvaluation(evaluation)">
										Vuelta Nº {{ evaluation.iterationNumber }} - {{ evaluation.puestoName }} - {{
										evaluation.namePeriod }}
									</mat-option>
								</ng-container>
								<ng-container *ngIf="(tandemId == projectId)">
									<mat-option *ngFor="let evaluation of allUserEvaluations"
										(click)="loadEvaluation(evaluation)">
										Registro {{ getFecha(evaluation.creationDate) }}
									</mat-option>
								</ng-container>

							</mat-select>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div fxLayout="column" *ngIf=" collaborators?.length">
			<div class="colaboradores-header" fxLayout="row wrap" fxLayoutAlign="space-between center"
				*ngIf="(tandemId != projectId)">
				<div fxLayout="row" fxLayoutAlign="center">
					<div fxLayout="row" fxLayoutAlign="center">
						<h2>{{ collaborators?.length }} Colaboradores</h2>
					</div>
				</div>

				<div class="filters" fxLayout="row" fxLayoutAlign="center center">
					<div fxLayout="row" fxLayoutAlign="center center" *ngIf="(tandemId != projectId)">
						<span class="mr-24"><strong>Filtrar por:</strong></span>
						<mat-form-field subscriptSizing="dynamic" dense appearance="outline">
							<mat-select class="mr-24" #filterSelect required
								(selectionChange)="onChangeFilter(filterSelect.value)">
								<mat-option value="null">-----</mat-option>
								<mat-option *ngFor="let p of listaPuestos" [value]="p">{{ p }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div fxLayout="row" fxLayoutAlign="center center" *ngIf="(tandemId != projectId)">
						<span class="mr-24"><strong>Ordenar por:</strong></span>
						<mat-form-field subscriptSizing="dynamic" dense appearance="outline">
							<mat-select #sortSelect required (selectionChange)="onChangeSort(sortSelect.value)">
								<mat-option value="null">-----</mat-option>
								<mat-option value="avgEvaluations">Mejor valorado</mat-option>
								<mat-option value="iterationNumber">Menor número de vueltas</mat-option>
								<mat-option value="updateDate">Última vuelta realizada</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</div>

			<!-- <div class="alert-warning" fxLayout="column" fxLayoutAlign="none">                
                <div fxLayout="column" fxLayoutAlign="none">
                    <span>Se recomienda configurar las familias de los colaboradores con puestos <span class="bold">VENDEDOR/A</span> para facilitar la evaluación de la Vuelta D.</span>
                    <span>Para ello pulsa el siguiente icono <mat-icon>settings</mat-icon> sobre cada colaborador que aún no hayas configurado o requiera algún cambio.</span>
                </div>
            </div> -->

			<div @slideUpList *ngIf="collaborators?.length && tandemId != projectId" class="colaboradores"
				fxLayout="row wrap" fxLayoutAlign="space-evenly left">
				<div *ngFor="let collaborator of collaborators" fxLayout="row">
					<div class="fuse-card card-border-top card-colaborador">
						<!-- Tranfer -->
						<div class="transfer">
							<mat-icon *ngIf="collaborator.type === 'TRANSFER'" class="green"
								[title]="'Cedido por ' + collaborator.originalEvaluator">arrow_forward</mat-icon>
							<mat-icon *ngIf="collaborator.transferedTo" class="pointer red"
								[title]="'Cedido a ' + collaborator.transferedTo.newEvaluatorName"
								(click)="openTransferDialog(collaborator)">arrow_back</mat-icon>
						</div>

						<div class="transfer-menu" *ngIf="tandemId != projectId">
							<button mat-icon-button [matMenuTriggerFor]="colaboratorMenu"
								class="quick-panel-toggle-button menu-button">
								<mat-icon>menu</mat-icon>
							</button>
						</div>

						<mat-menu #colaboratorMenu="matMenu" [overlapTrigger]="false">
							<!-- <button mat-menu-item (click)="openJobSection(collaborator)">
                                <mat-icon class="m-0">settings</mat-icon>
                                Configurar secciones
                            </button> -->
							<button mat-menu-item (click)="openTransferDialog(collaborator)">
								<mat-icon class="m-0">arrow_back</mat-icon>
								Cesión de Vuelta D
							</button>
						</mat-menu>

						<div fxLayout="column" fxLayoutAlign="center center">
							<div class="person-avatar" fxLayout="row" fxLayoutAlign="center flex-start">
								<img class="img-circle" [src]="getImagePerson(collaborator?.nickname)"
									[alt]="collaborator?.nickname" />
							</div>
							<h5 class="text-center">{{ collaborator.displayName }}</h5>
							<h5 class="text-center">{{ collaborator.puesto?.puesto }}</h5>

							<div class="m-4" fxLayout="row" fxLayoutAlign="flex-start center"
								*ngIf=" (tandemId != projectId)">
								<span class="mr-16">Valoración:</span>
								<!-- <item-stars [stars]="getStarsIndex(evaluator.avgEvaluations)"></item-stars> -->
								<span *ngFor="let star of getStarsIndex(collaborator.avgEvaluations)">
									<mat-icon class="golden" *ngIf="star == 'X'">star</mat-icon>
									<mat-icon class="gray" *ngIf="star == '-'">star</mat-icon>
								</span>
								<!-- <span class="ml-24" (click)="getBlockByUser(collaborator.personId)">
                                    <mat-icon class="eye">visibility</mat-icon>
                                </span> -->
							</div>

							<div *ngIf="collaborator.isBlockHidden === '1'" @slideUpList>
								<div *ngFor="let block of collaborator.block" class="m-4" fxLayout="row"
									fxLayoutAlign="flex-start center">
									<span class="mr-16 center-text">{{ block.blockname }}</span>
									<!-- <item-stars [stars]="getStarsIndex(block.blockAverage)"></item-stars> -->
									<span *ngFor="let star of getStarsIndex(block.blockAverage)">
										<mat-icon class="golden" *ngIf="star == 'X'">star</mat-icon>
										<mat-icon class="gray" *ngIf="star == '-'">star</mat-icon>
									</span>
									<span class="ml-24">{{ block.blockAverage.toFixed(2) }}</span>
								</div>
							</div>

							<div class="m-4" fxLayout="row" fxLayoutAlign="flex-start center">
								<span class="mr-8">Total enviadas este año:</span>
								<span
									[ngClass]="collaborator.iterationNumber == 0 ? 'red' : collaborator.iterationNumber >= 4 ? 'green' : 'orange'"
									class="vueltas">{{ collaborator.totalEvaluations }}</span>
							</div>
							<div class="m-4" fxLayout="row" fxLayoutAlign="flex-start center">
								<span class="mr-8">Últ. registro:</span>
								<span>{{ formatDate(collaborator.updateDate) }}</span>
							</div>

							<div *ngIf="collaborator.formId && (tandemId != projectId)">
								<div *ngIf="collaborator.status === 'DONE' || !collaborator.status" class="m-4"
									fxLayout="row" fxLayoutAlign="flex-start center">
									<button mat-raised-button class="btn-feedbalia btn-colaborator"
										[disabled]="collaborator.transferedTo"
										(click)="loadCollaboratorEvaluation(collaborator, true)">
										Nueva Vuelta D
									</button>
								</div>
								<div *ngIf="collaborator.status === 'DRAFT'" class="m-4" fxLayout="row"
									fxLayoutAlign="flex-start center">
									<button mat-raised-button class="btn-feedbalia btn-colaborator"
										[disabled]="collaborator.transferedTo"
										(click)="loadCollaboratorEvaluation(collaborator, false)">
										Continuar Vuelta D Nº{{ collaborator.iterationNumber }}
									</button>
								</div>
							</div>
							<div *ngIf="(!collaborator.formId) && (tandemId != projectId)">
								<div class="m-4 not-available" fxLayout="row" fxLayoutAlign="flex-start center">No
									disponible</div>
							</div>

							<a *ngIf=" (tandemId != projectId)" [href]="getCalendarLink(collaborator)" target="_blank"
								class="m-8 clickable" fxLayout="row" fxLayoutAlign="flex-start center">
								<mat-icon class="icon-calendar">event</mat-icon>
								<h6 class="m-0 pl-4 font-size-14">Agendar reunión</h6>
							</a>
							<a *ngIf=" (tandemId != projectId)" class="m-8 pointer" fxLayout="row"
								fxLayoutAlign="flex-start center" (click)="openFormaciones(collaborator)">
								<mat-icon class="icon-calendar">school</mat-icon>
								<h6 class="m-0 pl-4 font-size-14">Recomendar formaciones</h6>
							</a>

							<mat-select *ngIf="collaborator.allEvaluations && collaborator.allEvaluations.length"
								class="select-vueltas" panelClass="select-panel" placeholder="Histórico">
								<ng-container *ngIf=" (tandemId != projectId)">
									<mat-option *ngFor="let evaluation of collaborator.allEvaluations"
										(click)="loadEvaluation(evaluation)">
										{{ evaluation.namePeriod }} Vuelta Nº {{ evaluation.iterationNumber }} - {{
										evaluation.puestoName }}
									</mat-option>
								</ng-container>
								<ng-container *ngIf=" (tandemId == projectId)">
									<mat-option *ngFor="let evaluation of collaborator.allEvaluations"
										(click)="loadEvaluation(evaluation)">
										Registro {{ getFecha(evaluation.creationDate) }}
									</mat-option>
								</ng-container>

							</mat-select>
						</div>
					</div>
				</div>
			</div>
			<div @slideUpList *ngIf="collaborators?.length && tandemId == projectId" class="colaboradores"
				fxLayout="row" fxLayoutAlign="space-evenly left">
				<app-tandem-calendar style="width: 80%"> </app-tandem-calendar>
			</div>
		</div>
	</div>
</div>