<div id="vuelta-d" class="page-layout simple fullwidth p-24">
	<div @onRenderUp class="fuse-card card-border-top card-vuelta-d">
		<div *ngIf="!isEvaluator" fxLayout="column" class="p-24">
			<h2>No tienes permisos para visualizar esta evaluación.</h2>
			<div fxLayout="row" class="go-back" (click)="goBack()"><mat-icon class="mr-4">arrow_back</mat-icon><span
					class="font-size-16">Atrás</span></div>
		</div>

		<div *ngIf="isEvaluator" fxLayout="row wrap">
			<div fxFlex="25" class="px-20 card-part-left">
				<h5 class="m-0 mt-12 font-size-14 text-center">
					<b *ngIf="(tandemId != projectId)">{{ periodoSeleccionado && periodoSeleccionado.name.toUpperCase()
						}} </b>
					<b *ngIf="(tandemId == projectId) && evaluation?.creationDate">{{ getFecha(evaluation.creationDate)
						}} </b>
				</h5>
				<div *ngIf="userToEvaluate" fxLayout="row" class="mt-12">
					<div class="person-avatar mr-24" fxLayout="row" fxLayoutAlign="center flex-start">
						<img class="img-circle" [src]="getImagePerson(userToEvaluate.username)"
							[title]="userToEvaluate.displayName" />
					</div>
					<div fxLayout="column" class="perfil">
						<h5 class="m-0 font-size-16">{{ userToEvaluate.displayName }}</h5>
						<h6 class="m-0 font-size-12">{{ evaluationForm?.name }}</h6>
						<p class="m-0 mt-4 font-size-10">{{ userToEvaluate.empresa }}</p>
					</div>
				</div>

				<div *ngIf="currentPartial" class="mt-12 progressBar">
					<mat-progress-bar mode="determinate" value="{{ progressBar }}"></mat-progress-bar>
				</div>

				<div *ngIf="evaluationForm?.blocks" class="stepTimeLine">
					<mat-vertical-stepper [linear]="false" #stepperEval (selectionChange)="setIndex($event)">
						<mat-step *ngFor="let block of evaluationForm.blocks" state="number" completed="false">
							<ng-template matStepLabel>
								<p class="m-0">{{ block.namePrint }}</p>
								<div
									*ngIf="evaluation && evaluation.evaluators && evaluation.evaluators.length &&  (tandemId != projectId)">
									<item-stars *ngIf="block.sections !== undefined"
										[stars]="getBlockStarsIndex(block)"></item-stars>
								</div>
							</ng-template>
						</mat-step>
					</mat-vertical-stepper>
				</div>

				<div class="nota mb-32">
					<div class="score">
						<h2 *ngIf="evaluation && (projectId != tandemId)" class="m-0 mb-8 font-size-30 text-center">
							<b class="font-size-36">{{ evaluationResult.toFixed(2) }}</b>/100
						</h2>
						<div class="mt-4" fxLayout="column" fxLayoutAlign="center center"
							*ngIf="tandemId!=projectId || user.id == userToEvaluate.id">
							<button *ngIf="editMode && (projectId != tandemId)" mat-raised-button
								class="m-8 btn-outline btn-borrador" (click)="saveDraft()">
								<mat-icon>get_app</mat-icon>
								Guardar como borrador
							</button>
							<button *ngIf="editMode" mat-raised-button class="m-8 btn-outline btn-feedbalia"
								(click)="openDialog()">
								<mat-icon>send</mat-icon>
								Enviar registro
							</button>
						</div>
						<div class="mt-4" fxLayout="column" fxLayoutAlign="center center"
							*ngIf="tandemId!=projectId || user.id == userToEvaluate.id">
							<small fxLayout="row" fxLayoutAlign="center center" *ngIf="(projectId != tandemId)">
								<mat-icon class="s-14 mr-4">check</mat-icon>
								Borrador guardado
							</small>
							<h6 *ngIf="evaluation?.evaluators?.length" class="m-0 mt-4 font-size-12 text-center">
								Última modificación:
								<span>{{ getLastUpdateDate() }}</span>
							</h6>
							<div *ngIf="editMode && tandemId!=projectId" class="mt-4 autoguardado">
								<mat-icon class="s-14 mr-4">info</mat-icon>
								<span>Próximo guardado en {{ countDown }} segundos</span>
							</div>
						</div>
					</div>
					<h6 fxLayout="row" fxLayoutAlign="center center" class="m-0 mt-16" (click)="goBack()">
						<mat-icon class="s-16">arrow_back</mat-icon>
						<a class="ml-4 go-back">Salir de la evaluación</a>
					</h6>
				</div>
			</div>

			<div *ngIf="evaluationForm?.blocks" fxFlex="75" class="px-16 py-24 card-part-right">
				<div @onNextStep *ngIf="animationState">
					<div *ngFor="let block of evaluationForm.blocks">
						<div *ngIf="evalStep === block.displayOrder" fxLayout="row wrap"
							fxLayoutAlign="space-between center" class="mb-12">
							<h4 class="m-0 mb-12 font-size-18 text-theme" fxFlex="80">
								Valora a {{ userToEvaluate && userToEvaluate.displayName }} en {{ block.namePrint }}
							</h4>
							<div>
								<button *ngIf="evalStep != 0" mat-raised-button class="mr-8 btn-outline btn-feedbalia"
									(click)="previusStep()">Anterior</button>
								<button *ngIf="evalStep != evaluationForm.blocks.length - 1" mat-raised-button
									class="btn-feedbalia" (click)="nextStep()">
									Siguiente
								</button>
							</div>
						</div>

						<div *ngIf="evalStep === block.displayOrder">
							<div *ngFor="let section of block.sections; let i = index"
								class="panel panel-card panel-theme">
								<div fxLayout="row" fxLayoutAlign="space-between center" class="panel-heading"
									style="cursor: pointer" (click)="tooglePanel(section)">
									<h3 class="panel-title">
										<b>{{ section.namePrint }}</b>
									</h3>
									<mat-icon class="s-18">{{ !section.reduced ? "keyboard_arrow_down" :
										"keyboard_arrow_right" }}</mat-icon>
								</div>
								<div *ngIf="!section.reduced">
									<div *ngFor="let element of section.elements" fxLayout="column"
										class="panel panel-theme" [id]="'panel-' + element.id"
										style="margin:  5px 5px 5px 5px;">

										<div fxLayout="row">
											<div fxLayout="column" fxFlex="4 1 0">
												<h5 style="margin-left: 20px;" (click)="printElementResults()">{{
													element.name }}</h5>
											</div>
											<div fxLayout="column" fxFlex="1 1 0">
												<div *ngIf="element.type !== 'SELECT'" fxLayout="row"
													fxLayoutAlign="flex-end end">
													<div fxLayout="column" class="panel-status">
														<div fxLayout="row" fxLayoutAlign="flex-end center"
															class="mb-8">
															<div fxLayout="column">
																<div *ngFor="let evaluator of evaluation.evaluators"
																	[ngClass]="evaluator.id === user.id ? 'me' : 'evaluator mt-8'"
																	fxLayout="row" fxLayoutAlign="end">
																	<div class="person-avatar mr-8" fxLayout="row"
																		fxLayoutAlign="center flex-start">
																		<img class="img-circle"
																			[src]="getImagePerson(evaluator.nickname)"
																			[title]="evaluator.displayName" />
																	</div>
																	<item-stars
																		[stars]="getElementStarsIndex(element, evaluator)"
																		[editable]="editMode"
																		[view]="evaluator.id === user.id ? 'me' : 'evaluator'"
																		(onStarsChange)="onChangeStarsValue($event, element)">
																	</item-stars>
																</div>
															</div>
														</div>

														<div *ngIf="element.kpiData" class="p-16">
															<kpi-data-component
																[data]="element.kpiData"></kpi-data-component>
														</div>
													</div>
												</div>
												<div *ngIf="element.type === 'SELECT'" fxLayout="row"
													fxLayoutAlign="flex-end end"
													style="margin-right: 20px;margin-top: 20px;">
													<!-- ReadOnly -->
													<div fxLayout="column" fxFlex="none">
														<div fxLayout="row"
															*ngIf="tandemId!=projectId || user.id == userToEvaluate.id">
															<div fxFlex="none">
																<item-select fxLayout="column" [element]="element"
																	[result]="getElementSelectIndex(element) "
																	[editable]="editMode"
																	[selectOptions]="selectOptions[element.id]"
																	(onStarsChange)="onChangeSelect($event, element)">
																</item-select>
															</div>
														</div>


														<div fxLayout="row"
															*ngFor="let evaluator of evaluation.evaluators">
															<ng-container
																*ngIf="user.id != evaluator.id && getElementFromPartial(element,evaluator.partial) && getElementFromPartial(element,evaluator.partial)!= 'NA'">
																<div fxFlex="none">
																	<item-select [element]="element"
																		[result]="getElementFromPartial(element,evaluator.partial)"
																		[editable]="false"
																		[selectOptions]="selectOptions[element.id]"></item-select>
																</div>
																<div class="person-avatar mr-8">
																	<img class="img-circle"
																		[src]="getImagePerson(evaluator.nickname)"
																		[title]="evaluator.displayName" />
																</div>
															</ng-container>

														</div>
													</div>

												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>

						<div *ngIf="evalStep === block.displayOrder" fxLayout="row" fxLayoutAlign="flex-end center">
							<button *ngIf="evalStep != 0" mat-raised-button class="mr-8 btn-outline btn-feedbalia"
								(click)="previusStep()">Anterior</button>
							<button *ngIf="evalStep != evaluationForm.blocks.length - 1" mat-raised-button
								class="btn-feedbalia" (click)="nextStep()">
								Siguiente
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>