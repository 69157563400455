<div id="formaciones-header" mat-dialog-container fxLayout="column" fxLayoutAlign="center center">
	<h1><strong>Recomendar formaciones</strong></h1>
	<p>Seleccione las formaciones que quiere recomendar para este empleado.</p>
</div>

<form>
	<mat-form-field subscriptSizing="dynamic" class="full-width">
		<input type="text" placeholder="Seleccione un curso" matInput [formControl]="formControl"
			[matAutocomplete]="auto" />
		<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onSelectCurso()">
			<mat-option *ngFor="let curso of filteredCursos | async" [value]="curso.name">
				{{ curso.name }}
			</mat-option>
		</mat-autocomplete>
		<button *ngIf="selectedCurso" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
			<mat-icon>close</mat-icon>
		</button>
	</mat-form-field>
</form>

<div @onRenderUp *ngIf="selectedCurso" class="curso-desc">
	<strong>Descripción</strong>
	<div [innerHTML]="selectedCurso.description"></div>
	<strong *ngIf="selectedCurso.objectives">Objetivos</strong>
	<div [innerHTML]="selectedCurso.objectives"></div>
</div>

<br />

<div class="buttons" fxLayout="row" fxLayoutAlign="end center">
	<button mat-raised-button class="m-8 btn-outline btn-feedbalia" (click)="confirm()">
		<strong>Enviar formación</strong>
	</button>
	<button mat-raised-button class="m-8 btn-outline btn-borrador" (click)="close()">
		<strong>Cancelar</strong>
	</button>
</div>