import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute } from "@angular/router";

import { FuseConfigService } from "@fuse/services/config.service";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ImagesService } from "app/services/helper/images.service";
import { PersonService } from "app/services/person/person.service";
import { PuestosService } from "app/services/puesto/puestos.service";
import { UserService } from "app/services/user/user.service";
import { Evaluation, EvaluationForm, Partial, Result } from "app/shared/models/vuelta-d.interface";
import { SendEvaluationComponent } from "../send-evaluation-dialog/send-evaluation-dialog.component";

import { feedAnimations } from "app/animations";
import { ProjectService } from "app/services/project/project.service";
import { LocalStorage } from "app/utils";
import moment from "moment";

@Component({
	selector: "app-vuelta-d-form",
	templateUrl: "./vuelta-d-form.component.html",
	styleUrls: ["./vuelta-d-form.component.scss"],
	animations: [feedAnimations],
})
export class VueltaDFormComponent implements OnInit {
	@ViewChild("stepperEval") private stepper: MatStepper;
	tandemId = this.projectSvc.TANDEM_PROJECT_ID;
	projectId;
	params: any;

	isEvaluator: boolean;

	user: any;
	userToEvaluate: any;
	evaluationForm: EvaluationForm;
	evaluation: Evaluation;
	periodoSeleccionado: any;
	puestoSeleccionado: any;

	progressBar: number;
	editMode: boolean;
	isAutoEvaluation: boolean;

	currentPartial: Partial;
	evaluationResult: number;
	previousElementsResults: any;
	partialFiles: Array<any>;

	evalStep: number;
	interval: any;
	countDown: number;
	isCountdownOff: boolean;
	animationState: boolean = true;

	selectOptions: any = {};
	elementResults: any = {};

	editorInstances = [];
	hoy;
	constructor(
		private _fuseConfigService: FuseConfigService,
		private _location: Location,
		private dialog: MatDialog,
		private route: ActivatedRoute,
		private personSvc: PersonService,
		private userSvc: UserService,
		private imageSvc: ImagesService,
		private evaluationSvc: EvaluationService,
		private puestosSvc: PuestosService,
		private projectSvc: ProjectService
	) {
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					secondaryBackground: "feedbalia-navbar2-500",
				},
				toolbar: {
					background: "feedbalia-navbar2-500",
				},
			},
		};
		this.evalStep = 0;
		this.editMode = false;
		this.countDown = 300;
		this.isCountdownOff = true;
		this.evaluationResult = 0;
		this.progressBar = 0;
		this.previousElementsResults = [];
		this.partialFiles = [];
	}

	ngOnInit(): void {
		this.hoy = moment().format("DD-MM-YYYY");
		this.projectId = parseInt(this.projectSvc.getProjectId());
		this.route.queryParams.subscribe((params) => {
			this.params = params;
			this.userSvc.getUser().subscribe((data) => {
				this.user = data;
				this.checkEvaluatorPermissions();
				this.personSvc.getPerson(params.personId).subscribe((data) => {
					this.userToEvaluate = data;
					//Comprobar si es autoevaluación
					this.isAutoEvaluation = this.userToEvaluate.id === this.user.id ? true : false;
					this.getCompany(params.personId);
					//Cargar inicial del formulario y evaluacion
					this.loadEvaluation(false).then(() => {
						this.loadEvaluationForm().then(() => {
							this.initEvaluation();
						});
					});
					//Cargar periodo
					this.evaluationSvc.getEvaluationPeriodById(params.periodId).subscribe((data) => {
						this.periodoSeleccionado = data;
					});
				});
			});
		});
	}

	ngOnDestroy() {
		if (!this.isCountdownOff) {
			clearInterval(this.interval);
		}
	}

	checkEvaluatorPermissions() {
		this.evaluationSvc.isEvaluator(this.params.personId, this.params.periodId, 1).subscribe((_isEvaluator) => {
			if (this.params.personId == this.user.id) {
				this.isEvaluator = true;
			} else if (_isEvaluator && _isEvaluator.length) {
				this.isEvaluator = true;
			} else {
				this.isEvaluator = false;
			}
		});
	}

	loadEvaluationForm() {
		return new Promise((resolve, reject) => {
			this.evaluationSvc
				.getEvaluationFormById(this.params.formId, this.params.personId, this.params.periodId, this.evaluation.id)
				.subscribe((data: EvaluationForm) => {
					this.evaluationForm = data;
					//Cargar los datos KPI
					this.loadKpiData();
					console.log("EvaluationForm", this.evaluationForm);
					resolve(true);
				});
		});
	}

	loadEvaluation(forceReadFromWs) {
		return new Promise((resolve, reject) => {
			if (false && !forceReadFromWs) {
			} else {
				this.evaluationSvc
					.getEvaluation(this.params.formId, this.params.personId, this.params.periodId, this.params.iterationNumber)
					.subscribe((data: Evaluation) => {
						//Si no existe, crear una nueva evaluación
						if (!data) {
							this.evaluation = {
								id: null,
								personId: this.params.personId,
								evaluationFormId: this.params.formId,
								periodId: this.params.periodId,
								creationDate: null,
								updateDate: null,
								iterationNumber: this.params.iterationNumber,
								totalResult: 0,
								partial: null,
								evaluators: [],
							};
						} else {
							this.evaluation = data;
						}

						this.evaluationSvc.getEvaluators(this.params.personId, null, this.params.periodId).subscribe((data: any) => {
							// Poner el usuario el primero
							const _evaluatorsSorted = [];
							data.forEach((e) => {
								if (e.id == this.user.id) {
									_evaluatorsSorted.unshift(e);
								} else {
									_evaluatorsSorted.push(e);
								}
							});
							this.evaluation.evaluators = _evaluatorsSorted;
							//Añadir el propio usuario si no está
							if (!this.evaluation.evaluators.find((ev) => ev.id === this.userToEvaluate.id)) {
								this.evaluation.evaluators.push({
									id: this.userToEvaluate.id,
									displayName: this.userToEvaluate.displayName,
									firstName: this.userToEvaluate.firstName,
									lastName: null,
									nickname: this.userToEvaluate.nickname,
								});
							}
							//Añadir los resultados de cada parcial a cada evaluador
							this.evaluation.evaluators.forEach((evaluator) => {
								// Si la evaluacion es nueva, crear los parciales
								if (!this.evaluation.id) {
									evaluator.partial = {
										id: null,
										comment: null,
										creationDate: null,
										updateDate: null,
										evaluationId: null,
										evaluatorId: evaluator.id,
										status: "DRAFT",
										results: [],
									};
								} else {
									this.evaluationSvc.getEvaluationVHPartialResultsByEvaluator(this.evaluation.id, evaluator.id).subscribe((data: Partial) => {
										//Si existe la evaluacion pero no el parcial
										if (!data) {
											evaluator.partial = {
												id: null,
												comment: null,
												creationDate: null,
												updateDate: null,
												evaluationId: null,
												evaluatorId: evaluator.id,
												status: "DRAFT",
												results: [],
											};
										} else {
											evaluator.partial = data;
										}
										//Guardar la evaluacion en LS. Este método se ejecuta al realizarse el guardado para actualizar los resultados.
										this.saveEvaluationInLocalStorage();
									});
								}
							});
							console.log("Evaluation", this.evaluation);
							resolve(true);
						});
					});
			}
		});
	}

	setResult(event, result) {
		let evt = {
			id: event.id,
			target: "elementId",
			type: event.type,
			value: result,
		};

		let partial = this.evaluation.evaluators.find((e) => e.id === this.user.id).partial;

		const _selectedElementResult = partial.results.find((r) => r.elementId === evt.id);
		if (_selectedElementResult) {
			_selectedElementResult.result = evt.value;
		} else {
			const _elementResult = {
				id: null,
				[evt.target]: evt.id,
				evaluationPartialId: partial.id,
				result: evt.value,
				type: evt.type,
			};
			partial.results.push(_elementResult);
		}

		this.calculateProgressBar();
		this.calculateEvaluationResult();
	}

	loadKpiData() {
		this.evaluationForm.blocks.forEach((block) => {
			block.sections.forEach((section) => {
				section.elements.forEach((element) => {
					this.elementResults[element.id] = this.evaluation.evaluators.find((e) => e.id === this.userToEvaluate.id).partial.results ?? null;

					// Se cargan los options de los metadatos.
					if (element["metadata"]) {
						this.selectOptions[element.id] = [];
						this.loadSelectOptions(element);
					}

					if (element.kpiId) {
						this.personSvc.getKpiDataByEvaluationElement(this.params.personId, element.kpiId).subscribe((data) => {
							element.kpiData = data;
						});
					}
					// Cargar comentarios en elementos
					this.initCommentsOfElement(element);
				});
			});
		});
	}

	loadSelectOptions(element) {
		console.log("element", element);
		const options = element.metadata ? JSON.parse(element.metadata) : {};
		Object.keys(options).forEach((o) => {
			this.selectOptions[element.id].push({
				name: o,
				value: options[o],
			});
		});

		console.log("selectOptions", this.selectOptions);
	}

	initEvaluation() {
		this.evaluationResult = this.evaluation.totalResult;
		//Ordenar los evaluadores para que el usuario aparezca el primero
		this.evaluation.evaluators.sort((a, b) => (a.id === this.user.id ? -1 : b === this.user.id ? 1 : 0));
		this.evaluation.evaluators.forEach((evaluator) => {
			if (evaluator.id === this.user.id) {
				this.editMode = evaluator.partial && evaluator.partial.status === "DONE" ? false : true;
				this.currentPartial = evaluator.partial;
				this.loadEvaluationFiles();
			}
			this.setValoresNA(evaluator.partial);
			this.calculateProgressBar();
			//this.initCountdown();
		});
	}

	setValoresNA(partial) {
		if (!partial) {
			return;
		}
		let _existingResults = partial.results ? partial.results.filter((r) => r.elementId || r.sectionId || r.blockId) : [];
		_existingResults = _existingResults.map((r) => r.elementId || r.sectionId || r.blockId);
		//Get todos los elementos de la evaluación
		let _allResultsOfEvaluation = [];
		this.evaluationForm.blocks.forEach((block) => {
			_allResultsOfEvaluation.push({ blockId: block.id });
			block.sections.forEach((section) => {
				_allResultsOfEvaluation.push({ sectionId: section.id });
				section.elements.forEach((element) => {
					_allResultsOfEvaluation.push({ elementId: element.id });
				});
			});
		});
		//Añadir al parcial los elementos que faltan con valores NA
		const _naResults = _allResultsOfEvaluation.filter((r) => !_existingResults.includes(r.elementId || r.sectionId || r.blockId));
		_naResults.forEach((result) => {
			const _naResult: Result = {
				id: null,
				evaluationPartialId: partial.id,
				blockId: result.blockId || null,
				sectionId: result.sectionId || null,
				elementId: result.elementId || null,
				comment: null,
				result: "NA",
			};
			partial.results.push(_naResult);
		});
	}

	getImagePerson(nickname: string) {
		return this.imageSvc.getImageThumb(nickname);
	}

	getCompany(personId) {
		this.personSvc.getCompany(personId).subscribe((data) => {
			this.userToEvaluate.empresa = data[0].empresa;
		});
	}

	goBack() {
		this._location.back();
	}

	nextStep() {
		this.animate();
		this.evalStep++;
		this.stepper.next();
		this.scrollTop();
	}

	previusStep() {
		this.animate();
		this.evalStep--;
		this.stepper.previous();
		this.scrollTop();
	}

	setIndex(event) {
		this.evalStep = event.selectedIndex;
		this.animate();
	}

	scrollTop() {
		const _el = document.getElementById("vuelta-d");
		_el.scrollIntoView();
	}

	tooglePanel(section) {
		section.reduced = !section.reduced;
	}

	getLastUpdateDate() {
		const userPartial = this.evaluation.evaluators.find((evaluator) => evaluator.id === this.user.id)?.partial;
		if (!userPartial) {
			return;
		}
		let _date = moment(userPartial.updateDate ? userPartial.updateDate : userPartial.creationDate).format("DD/MM/YYYY HH:mm");
		return _date;
	}

	getElementStarsIndex(element, evaluator) {
		if (!evaluator.partial || !evaluator.partial.results.length) {
			return ["-", "-", "-", "-", "-"];
		}
		const elementResults = evaluator.partial.results.find((r) => r.elementId === element.id);
		return this.printStars(elementResults ? elementResults.result : 0);
	}

	getElementSelectIndex(element) {
		const elementResults = this.currentPartial?.results.find((r) => r.elementId === element.id);
		return elementResults ? elementResults.result : null;
	}

	getElementFromPartial(element, partial) {
		const elementResults = partial?.results.find((r) => r.elementId === element.id);
		return elementResults ? elementResults.result : null;
	}

	getBlockStarsIndex(block) {
		let _partialResults;
		//Si es autoevaluación, el resultado será el que haya dado el evaluador
		if (this.isAutoEvaluation && this.evaluation.evaluators.length >= 2) {
			_partialResults = this.evaluation.evaluators.find((ev) => ev.id !== this.user.id);
		} else {
			_partialResults = this.evaluation.evaluators.find((ev) => ev.id === this.user.id);
		}

		if (!_partialResults.partial || !_partialResults.partial.results.length) {
			return this.printStars(0);
		}

		const blockResults = _partialResults.partial.results.filter((r) => r.blockId === block.id);
		if (!blockResults || !blockResults.length) {
			return this.printStars(0);
		}
		//Si el bloque tiene varios results (no debería) coger el mas actual.
		const _blockResult = parseFloat(blockResults[blockResults.length - 1].result);
		return this.printStars(_blockResult);
	}

	printStars(result) {
		let _index;
		if (!result) {
			_index = 0;
		} else {
			_index = Math.floor(result / 16.66);
		}

		let _array = new Array(5);
		for (let i = 0; i < 5; i++) {
			if (i < _index) {
				_array[i] = "X";
			} else {
				_array[i] = "-";
			}
		}
		return _array;
	}

	onCommentChange(evt, elm) {
		if (!this.currentPartial) {
			return;
		}
		const _selectdElement = this.currentPartial?.results.find((r) => r.elementId === elm.id);
		_selectdElement.comment = evt.html;
		console.log(this.currentPartial);
		this.saveEvaluationInLocalStorage();
	}

	initEditor(instance, elm) {
		// Iniciar instancias
		this.editorInstances.push({ element: elm.id, instance: instance });
		const toolbar = instance.getModule("toolbar");
		toolbar.addHandler("image", this.uploadImageHandler.bind(this, elm.id));
		// Si hay contenido en el editor en borrador
		if (this.currentPartial) {
			const _selectdElement = this.currentPartial?.results.find((r) => r.elementId === elm.id);
			if (_selectdElement.comment) {
				instance.root.innerHTML = _selectdElement.comment;
			}
		}
	}
	uploadImageHandler(elemID) {
		const fr = new FileReader();
		let _input = document.getElementById("file-input");
		_input.click();
		_input.onchange = (evt: Event) => {
			const _file = (evt.target as HTMLInputElement).files[0];
			const formData = new FormData();
			formData.append("file", _file);
			this.evaluationSvc.uploadFile(elemID, this.currentPartial, formData).subscribe((data) => {
				console.log(data);
				// this.onFileChange.emit(data);
			});
		};
	}

	initCommentsOfElement(element) {
		let elementComments = [];

		this.evaluation.evaluators.forEach((evaluator) => {
			const currentElement = evaluator.partial && evaluator.partial.results.find((result) => result.elementId == element.id);
			if (currentElement && currentElement.comment) {
				elementComments.push({
					comment: currentElement.comment,
					evaluator: evaluator,
				});
			}
		});

		element.comments = elementComments;
	}

	onChangeStarsValue(evt, elm) {
		if (this.currentPartial) {
			let selectedElement = this.currentPartial?.results.find((el) => el.elementId === elm.id);
			//Editar el resultado
			if (selectedElement) {
				selectedElement.result = this.calculateElementResult(evt);
			}
			//Añadir el resultado
			else {
				const _newResult: Result = {
					id: null,
					evaluationPartialId: this.currentPartial.id,
					blockId: null,
					sectionId: null,
					elementId: elm.id,
					comment: null,
					result: this.calculateElementResult(evt),
				};
				this.currentPartial?.results.push(_newResult);
			}
			this.calculateBlockResult(elm);
			this.calculateProgressBar();
			// Actualizar el parcial del usuario
			this.evaluation.evaluators[0].partial = this.currentPartial;
		}
	}
	onChangeSelect(evt, elm) {
		if (this.currentPartial) {
			let selectedElement = this.currentPartial?.results.find((el) => el.elementId === elm.id);
			//Editar el resultado
			if (selectedElement) {
				selectedElement.result = this.calculateElementResult(evt);
			}
			//Añadir el resultado
			else {
				const _newResult: Result = {
					id: null,
					evaluationPartialId: this.currentPartial.id,
					blockId: null,
					sectionId: null,
					elementId: elm.id,
					comment: null,
					result: this.calculateElementResult(evt),
				};
				this.currentPartial?.results.push(_newResult);
			}
			this.calculateBlockResult(elm);
			this.calculateProgressBar();
			// Actualizar el parcial del usuario
			this.evaluation.evaluators[0].partial = this.currentPartial;
		}
	}

	calculateElementResult(evt) {
		if (evt.includes("_")) {
			return evt;
		} else {
			const result = evt.filter((r) => r === "X");
			return result.length * 20;
		}
	}

	calculateBlockResult(elm) {
		//Si es autoevaluación, el resultado a mostrar será el del evaluador
		if (this.isAutoEvaluation) {
			return;
		}

		const _selectedBlock = this.evaluationForm.blocks.find((b) => b.sections.find((s) => s.id === elm.sectionId));
		//Ids de todos los elementos perteneciente al bloque
		let _elementsIds = [];
		_selectedBlock.sections.forEach((section) => {
			section.elements.forEach((element) => _elementsIds.push(element.id));
		});
		//Resultados del bloque seleccionado
		const _selectedBlockResults = this.currentPartial?.results.filter((r) => _elementsIds.includes(r.elementId));
		//Calcular result del bloque por peso de secciones y elementos

		let _blockResult = 0;
		let _totalPonderation = 0;
		let _hasNAElements = false;
		//Calculamos la ponderacion total
		_selectedBlock.sections.forEach((section) => {
			let _sectionResult = 0;
			section.elements.forEach((element) => {
				const _elementResult = _selectedBlockResults.find((r) => r.elementId === element.id);
				if (_elementResult.result !== "NA") {
					const _ponderacion = element.weight / 100;
					_totalPonderation += _ponderacion;
					_sectionResult += Number(_elementResult.result) * _ponderacion;
				} else {
					_hasNAElements = true;
				}
			});
			_blockResult += _sectionResult;
			const _currentSection = this.currentPartial?.results.find((r) => r.sectionId === section.id);
			_currentSection.result = this.roundTwoDecimals(_sectionResult);
		});
		//Si hay elementos NA, volvemos a calcular las ponderaciones
		const _ponderationFactor = 1 / _totalPonderation;
		if (_hasNAElements) {
			_blockResult = 0;
			_selectedBlock.sections.forEach((section) => {
				let _sectionResult = 0;
				section.elements.forEach((element) => {
					const _elementResult = _selectedBlockResults.find((r) => r.elementId === element.id);
					if (_elementResult.result !== "NA") {
						const _ponderacion = element.weight / 100;
						_sectionResult += Number(_elementResult.result) * (_ponderacion * _ponderationFactor);
					}
				});
				_blockResult += _sectionResult;
				const _currentSection = this.currentPartial?.results.find((r) => r.sectionId === section.id);
				_currentSection.result = this.roundTwoDecimals(_sectionResult);
			});
		}
		const blockResult = this.currentPartial?.results.find((r) => r.blockId === _selectedBlock.id);
		blockResult.result = this.roundTwoDecimals(_blockResult);
		//Recalcular el total de la evaluación
		this.calculateEvaluationResult();
	}

	calculateEvaluationResult() {
		let _partialResult = 0;
		let _totalPonderation = 0;
		let _hasNABlock = false;
		//Calculamos la ponderacion total
		const _allBlocks = this.evaluationForm.blocks;
		const _allBlockResults = this.currentPartial?.results.filter((r) => r.blockId);
		_allBlocks.forEach((block) => {
			const _currentBlockResult = _allBlockResults.find((r) => r.blockId === block.id);
			if (_currentBlockResult.result != "NA") {
				const _ponderacion = block.weight / 100;
				_totalPonderation += _ponderacion;
				_partialResult += Number(_currentBlockResult.result) * _ponderacion;
			} else {
				_hasNABlock = true;
			}
		});
		//Si hay elementos NA, volvemos a calcular las ponderaciones
		const _ponderationFactor = 1 / _totalPonderation;
		if (_hasNABlock) {
			_partialResult = 0;
			_allBlocks.forEach((block) => {
				const _currentBlockResult = _allBlockResults.find((r) => r.blockId === block.id);
				if (_currentBlockResult.result != "NA") {
					const _ponderacion = block.weight / 100;
					_totalPonderation += _ponderacion;
					_partialResult += Number(_currentBlockResult.result) * (_ponderacion * _ponderationFactor);
				}
			});
		}
		//Guardar el resultado final
		this.evaluationResult = this.roundTwoDecimals(_partialResult);
		this.evaluation.totalResult = this.evaluationResult;
		//Guardar la EV en LS al cambiar el valor de algún elemento
		this.saveEvaluationInLocalStorage();
	}

	calculateProgressBar() {
		if (!this.currentPartial) {
			return;
		}

		if (this.currentPartial.status === "DONE") {
			this.progressBar = 100;
		} else {
			const _allElements = this.currentPartial?.results.filter((r) => r.elementId);
			const _total = _allElements.length;
			const _completed = _allElements.filter((r) => r.result !== "NA").length;
			this.progressBar = (_completed * 100) / _total;
		}
	}

	initCountdown() {
		if (this.editMode && this.isCountdownOff) {
			this.isCountdownOff = false;
			this.interval = setInterval(() => {
				this.countDown -= 1;
				//Guardar borrador y reiniciar
				if (this.countDown === 0) {
					this.saveDraft();
					this.countDown = 300;
				}
			}, 1000);
		}
	}

	saveDraft() {
		let _body = this.mapEvaluation();
		_body.partial.status = "DRAFT";
		this.evaluationSvc.saveEvaluation(_body, "VH").subscribe((data) => {
			if (data.response === "OK") {
				this.loadEvaluation(true);
			}
		});
	}

	sendEvaluation() {
		let _body = this.mapEvaluation();
		_body.partial.status = "DONE";
		this.evaluationSvc.saveEvaluation(_body, "VH").subscribe((data) => {
			if (data.response === "OK") {
				this.dialog.closeAll();
				clearInterval(this.interval);
				this.loadEvaluation(true).then(() => {
					this.editMode = false;
				});
			}
		});
	}

	openDialog() {
		const dialogRef = this.dialog.open(SendEvaluationComponent, {
			panelClass: ["panel-vuelta-d"],
			width: "50vw",
			disableClose: false,
			autoFocus: false,
			data: {
				confirm: this.sendEvaluation.bind(this),
				close: () => dialogRef.close(),
			},
		});
	}

	mapEvaluation() {
		//Solo se envian los elementos con resultados
		let _partial = Object.assign({}, this.currentPartial);
		_partial.results = this.currentPartial?.results.filter((r) => r.result !== "NA" && (r.elementId || r.blockId || r.sectionId));
		// _partial.results = this.currentPartial?.results.filter(r => r.result !== 'NA' && (r.elementId));
		_partial.status = "DRAFT";
		//Se añade el parcial a guardar en la evaluacion
		let _evaluation = Object.assign({}, this.evaluation);
		_evaluation.evaluators = null;
		_evaluation.partial = _partial;
		return _evaluation;
	}

	roundTwoDecimals(value: number) {
		return Math.round(value * 100) / 100;
	}

	isEvaluationInLocalStroage(): boolean {
		const _key = this.params.formId + this.params.personId + this.params.periodId + this.params.iterationNumber;
		return LocalStorage.get(_key) ? true : false;
	}

	saveEvaluationInLocalStorage() {
		const _key = this.params.formId + this.params.personId + this.params.periodId + this.params.iterationNumber;
		LocalStorage.save(_key, this.evaluation);
	}

	loadEvaluationFromLocalStorage() {
		const _key = this.params.formId + this.params.personId + this.params.periodId + this.params.iterationNumber;
		return LocalStorage.get(_key);
	}

	loadPreviousElementResults(elementId) {
		const _selectedElement = this.previousElementsResults.find((e) => e.id === elementId);
		if (_selectedElement) {
			_selectedElement.isOpen = !_selectedElement.isOpen;
			return;
		}
		this.evaluationSvc.getPreviousElementResult(this.params, this.user.id, elementId).subscribe((data) => {
			//Añadir los datos que faltan
			for (let i = 1; i <= this.params.iterationNumber - 1; i++) {
				if (!data.find((d) => d.iterationNumber === i)) {
					data.push({
						elementId: elementId,
						iterationNumber: i,
						isNull: true,
					});
				}
			}

			this.previousElementsResults.push({
				id: elementId,
				isOpen: true,
				currentIteration: this.params.iterationNumber - 1,
				data: data,
			});
		});
	}

	nextIteration(data) {
		if (data.currentIteration >= this.params.iterationNumber - 1) {
			return;
		}
		data.currentIteration = data.currentIteration + 1;
	}

	previousIteration(data) {
		if (data.currentIteration <= 1) {
			return;
		}
		data.currentIteration = data.currentIteration - 1;
	}

	loadEvaluationFiles() {
		this.evaluationSvc.getFilesFromEvaluation(this.evaluation.id).subscribe((data) => {
			this.partialFiles = data;
		});
	}

	onFileChange(file) {
		let _partialFiles = Object.assign([], this.partialFiles);
		if (file.idDeleted) {
			_partialFiles = _partialFiles.filter((f) => f.id !== file.idDeleted);
		} else {
			_partialFiles.push(file);
		}
		this.partialFiles = _partialFiles;
	}

	animate() {
		this.animationState = false;
		setTimeout(() => {
			this.animationState = true;
		}, 1);
	}

	printElementResults() {
		console.log(this.evaluation);
	}

	getFecha(fecha) {
		return moment(fecha).format("DD-MM-YYYY");
	}
}
