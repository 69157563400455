<div *ngIf="editable || filesOfElement" fxLayout="column" fxLayoutAlign="center center" class="files-container">
	<p>Archivos adjuntos:</p>
	<div fxLayout="row wrap" fxLayoutAlign="center center">
		<div *ngFor="let file of filesOfElement" fxLayout="column">
			<div class="p-4 thumb-container">
				<img *ngIf="file.contentType.includes('image')" [src]="filesPath + file.filePath" width="100" height="100" (click)="openFile(file)" />
				<img
					*ngIf="file.contentType.includes('video')"
					src="assets/images/file-manager/video.png"
					width="100"
					height="100"
					(click)="openFile(file)"
				/>
				<img
					*ngIf="!file.contentType.includes('image') && !file.contentType.includes('video')"
					src="assets/images/file-manager/document.png"
					width="100"
					height="100"
					(click)="openFile(file)"
				/>
			</div>
			<div *ngIf="currentUser" fxLayout="row" fxLayoutAlign="space-between" class="buttons-container">
				<div class="person-avatar" fxLayout="row" fxLayoutAlign="center flex-start">
					<img class="img-circle" [src]="getImagePerson(file.nickname)" [title]="file.displayName" />
				</div>
				<span (click)="downloadFile(file)">
					<mat-icon title="Descargar">get_app</mat-icon>
				</span>
				<span *ngIf="currentUser.id === file.personId && editable" (click)="removeFile(file)">
					<mat-icon title="Eliminar">clear</mat-icon>
				</span>
			</div>
		</div>

		<div *ngIf="editable">
			<div class="p-4 mb-28 thumb-container">
				<img src="assets/images/file-manager/add-file.png" width="100" height="100" (click)="addFile(file)" />
			</div>
		</div>
	</div>

	<!-- Hidden -->
	<input id="file-input" type="file" name="file-input" style="display: none" />
</div>
<div *ngIf="!partial || !partial.id" fxLayout="column" fxLayoutAlign="center center" class="files-container">
	<p>Por favor, guarde la evaluación en borrador para poder subir ficheros.</p>
</div>
