import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MediaDialogComponent } from "app/components/media-dialog/media-dialog.component";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ImagesService } from "app/services/helper/images.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";
import { User } from "app/shared/models/user.interface";
import { Element } from "app/shared/models/vuelta-d.interface";
import { environment } from "environments/environment";

import { saveAs } from "file-saver";

@Component({
	selector: "files-component",
	templateUrl: "./files.component.html",
	styleUrls: ["./files.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesComponent implements OnChanges {
	@Input() files: Array<any>;
	@Input() element: Element;
	@Input() editable: boolean;
	@Input() partial: any;

	@Output() onFileChange = new EventEmitter();

	filesOfElement: Array<any>;
	filesPath: string;
	dialogRef;
	currentUser: User;

	constructor(
		private http: HttpClient,
		private chager: ChangeDetectorRef,
		private userSvc: UserService,
		private projectSvc: ProjectService,
		private imageSvc: ImagesService,
		public dialog: MatDialog,
		public evaluationSvc: EvaluationService,
	) {
		this.filesPath = environment.urlDomainEvaluationFiles;
	}

	ngOnInit(): void {
		this.userSvc.getUser().subscribe((data) => {
			this.currentUser = data;
			this.chager.detectChanges();
		});
		if (this.files && this.element) {
			const _filesOfElement = this.files.filter((f) => f.elementId === this.element.id);
			this.filesOfElement = _filesOfElement.length ? _filesOfElement : null;
		}
	}

	ngOnChanges() {
		if (this.files && this.element) {
			const _filesOfElement = this.files.filter((f) => f.elementId === this.element.id);
			this.filesOfElement = _filesOfElement.length ? _filesOfElement : null;
		}
		if (!this.partial || !this.partial.id) {
			this.editable = false;
		}
	}

	getImagePerson(nickname: string) {
		return this.imageSvc.getImageThumb(nickname);
	}

	openFile(file) {
		this.dialogRef = this.dialog.open(MediaDialogComponent, {
			panelClass: ["panel-vuelta-d"],
			width: "50vw",
			disableClose: false,
			autoFocus: false,
			data: {
				file: file,
				close: this.close.bind(this),
			},
		});
	}

	close() {
		this.dialogRef.close();
	}

	downloadFile(file) {
		this.http.get(this.filesPath + file.filePath, { responseType: "blob" }).subscribe((data) => {
			const _blob = new Blob([data], { type: file.contentType });
			const _file = new File([_blob], file.fileName, { type: file.contentType });
			saveAs(_file);
		});
	}

	addFile() {
		let _input = document.getElementById("file-input");
		_input.click();
		_input.onchange = (evt: Event) => {
			const _file = (evt.target as HTMLInputElement).files[0];
			const formData = new FormData();
			formData.append("file", _file);
			this.evaluationSvc.uploadFile(this.element.id, this.partial, formData).subscribe((data) => {
				this.onFileChange.emit(data);
			});
		};
	}

	removeFile(file) {
		this.evaluationSvc.removeFile(file.id).subscribe((data) => {
			this.onFileChange.emit(data);
		});
	}
}
