<div id="job-section" class="page-layout simple fullwidth">
	<div *ngIf="isEvaluator === false" fxLayout="column" class="p-24">
		<h2>No tienes permisos para modificar este usuario.</h2>
		<div fxLayout="row" class="back-button" (click)="back()"><mat-icon class="mr-4">arrow_back</mat-icon><span class="font-size-16">Atrás</span></div>
	</div>

	<div *ngIf="isEvaluator" fxLayout="column" class="p-24">
		<h1>Configuración de secciones y subsecciones</h1>
		<h3>
			Estas son las secciones y subsecciones que tiene asignada el colaborador. Por favor añade o elimine las que considere oportuno para que los
			datos aparezcan correctamente en cada vuelta
		</h3>

		<div fxLayout="column">
			<div class="fuse-card card-border-top card-vuelta">
				<div *ngIf="person" fxLayout="row" class="person-header">
					<div fxLayout="row" class="mt-12">
						<div class="person-avatar mr-24" fxLayout="row" fxLayoutAlign="center flex-start">
							<img class="img-circle" [src]="getImagePerson(person.username)" [title]="person.displayName" />
						</div>
						<div fxLayout="column" class="perfil">
							<h5 class="m-0 font-size-16">{{ person.displayName }}</h5>
							<h6 class="m-0 font-size-12">{{ puesto }}</h6>
						</div>
					</div>
				</div>

				<br />
				<hr />
				<br />

				<div fxLayout="row" class="p-12 controllers">
					<select class="mr-24 section" matNativeControl #filterSection required (change)="onChangeSection(filterSection.value)">
						<option value="null">Selecciona una sección</option>
						<option *ngFor="let section of sectionList" [value]="section.id">{{ section.name }}</option>
					</select>
					<select class="mr-24 subsection" matNativeControl #filterSubsection required (change)="onChangeSubsection(filterSubsection.value)">
						<option value="null">Selecciona una subsección</option>
						<option *ngFor="let subsection of subsectionList" [value]="subsection.id">{{ subsection.name }}</option>
					</select>
					<button class="add-button button" (click)="addJobSection()">Añadir</button>
				</div>

				<div fxLayout="column" class="p-12 sections">
					<div *ngFor="let jobsection of currentJobSections" fxLayout="row">
						<div class="mr-24 section">{{ jobsection.sectionName.toUpperCase() }}</div>
						<div class="mr-24 subsection">{{ jobsection.subsectionName.toUpperCase() }}</div>
						<button class="remove-button button" (click)="removeJobSection(jobsection)">Eliminar</button>
					</div>
				</div>
			</div>

			<div fxLayout="row" class="back-button" (click)="back()">
				<mat-icon class="mr-4">arrow_back</mat-icon><span class="font-size-16">Atrás</span>
			</div>
		</div>
	</div>
</div>
