import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "item-select",
	templateUrl: "./item-select.component.html",
	styleUrls: ["./item-select.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemSelectComponent implements OnInit {
	@Input() element: any;
	@Input() result: any;
	@Input() editable: boolean;
	@Input() selectOptions: any;

	@Output() onStarsChange = new EventEmitter();

	// currentStars: Array<String>;

	constructor(public _changer: ChangeDetectorRef) {
	}

	ngOnInit(): void {
	}

	setStar() {
		this.onStarsChange.emit(this.result);
	}

}
