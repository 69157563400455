import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ImagesService } from "app/services/helper/images.service";
import { PersonService } from "app/services/person/person.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";
import { Persona } from "app/shared/models/user.interface";

@Component({
	selector: "app-job-section",
	templateUrl: "./job-section.component.html",
	styleUrls: ["./job-section.component.scss"],
})
export class JobSectionComponent implements OnInit {
	person: Persona;
	puesto: string;
	sectionList: Array<any>;
	subsectionList: Array<any>;

	selectedSubsection: number;
	currentJobSections: Array<any>;

	isEvaluator: boolean = true; //TODO

	constructor(
		private route: ActivatedRoute,
		private location: Location,
		private imageSvc: ImagesService,
		private personSvc: PersonService,
		private userSvc: UserService,
		private projectSvc: ProjectService,
		private evaluationSvc: EvaluationService,
	) {
		//Empty
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe((params) => {
			this.puesto = params.puesto;
			this.personSvc.getPerson(params.personId).subscribe((data) => {
				this.person = data;
			});
			this.personSvc.getJobSections(params.personId).subscribe((data) => {
				this.currentJobSections = data;
			});
		});
		this.evaluationSvc.getJobSections().subscribe((data) => {
			this.sectionList = data;
		});
	}

	getImagePerson(nickname: string) {
		return this.imageSvc.getImageThumb(nickname);
	}

	onChangeSection(parentId) {
		this.evaluationSvc.getJobSubsections(parentId).subscribe((data) => {
			this.subsectionList = data;
		});
	}

	onChangeSubsection(subsection) {
		this.selectedSubsection = subsection;
	}

	addJobSection() {
		if (!this.selectedSubsection) {
			return;
		}
		this.personSvc.addJobSections(this.person.id, this.selectedSubsection).subscribe((data) => {
			if (data.success) {
				this.personSvc.getJobSections(this.person.id).subscribe((data) => {
					this.currentJobSections = data;
				});
			}
		});
	}

	removeJobSection(jobSection) {
		this.personSvc.removeJobSections(this.person.id, jobSection.subsectionId).subscribe((data) => {
			if (data.success) {
				this.personSvc.getJobSections(this.person.id).subscribe((data) => {
					this.currentJobSections = data;
				});
			}
		});
	}

	back() {
		this.location.back();
	}
}
